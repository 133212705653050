import React from 'react';
import { Icon } from '@iconify/react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Menu from '../components/navigation';

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />
		<Menu />
		<div className="page-width align-center spacing-normal big-logo">
			<Icon icon="vb:big-logo" height="auto" />
			<Icon icon="vb:big-logo-text" height="auto" />
		</div>
		<div className="page-width align-center spacing-huge text-huge">
			<p>This page does not exist</p>
		</div>
	</Layout>
);

export default NotFoundPage;
